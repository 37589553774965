import { error } from "@multimediallc/logging";
export function mergeSortedArrays(comparer, ...arrs) {
    const merged = new Array(arrs.map((arr) => arr.length).reduce((a, b) => a + b, 0));
    let mergedIndex = 0;
    const inputIndexes = new Array(arrs.length).fill(0);
    const haveRemainingElements = () => inputIndexes.some((v, i) => v < arrs[i].length);
    const pickNextElement = () => {
        const nextElementCandidates = inputIndexes
            .map((arrayIndex, arrsIndex) => {
            return {
                value: arrs[arrsIndex][arrayIndex],
                arrsIndex,
            };
        })
            .filter((e) => e.value !== undefined);
        return nextElementCandidates.reduce((prev, curr) => {
            return comparer(prev.value, curr.value) < 0 ? prev : curr;
        }, nextElementCandidates[0]);
    };
    let needsSort = false;
    while (haveRemainingElements()) {
        const nextElement = pickNextElement();
        if (mergedIndex > 0 &&
            comparer(merged[mergedIndex - 1], nextElement.value) > 0) {
            error("mergeSortedArrays got unsorted input");
            needsSort = true;
        }
        merged[mergedIndex] = nextElement.value;
        inputIndexes[nextElement.arrsIndex] += 1;
        mergedIndex += 1;
    }
    if (needsSort) {
        merged.sort(comparer);
    }
    return merged;
}
