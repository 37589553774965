import type { ReactNode } from "react"
import { setupI18n } from "@lingui/core"
import { I18nProvider } from "@lingui/react"
import { error } from "@multimediallc/logging"
import { useAppContext } from "./hooks/appContext"
import type { MessageDescriptor } from "@lingui/core"

declare global {
    interface Window {
        i18njs?: { messages: Record<string, string> }
    }
}

export const jsi18n = setupI18n({
    productionCompileEnabled: true,
})

export function FlaggedI18nProvider({ children }: { children: ReactNode }) {
    const languageCode = useAppContext().context.language_code

    if (window.i18njs === undefined) {
        error("lingui active but window.i18njs is undefined", {
            locale: languageCode,
        })
        window.i18njs = { messages: {} }
    }
    jsi18n.load(languageCode, window.i18njs.messages)
    jsi18n.activate(languageCode)

    // @ts-ignore - pretty sure the typing fail on this line is a false positive
    return <I18nProvider i18n={jsi18n}>{children}</I18nProvider>
}

export function _(descriptor: MessageDescriptor): string {
    return jsi18n._(descriptor)
}
