import type { ComponentPropsWithRef, ReactNode } from "react"
import React from "react"
import { mergeClasses } from "../../../../utils/css"
import "./Button.scss"

export enum ButtonSize {
    None = "",
    Small = "ButtonSize-small", // 12px font-size and 24px min-height, or 24px height/width for icon-only content
    Medium = "ButtonSize-medium", // 16px font-size and 28px min-height, or 28px height/width for icon-only content
    Large = "ButtonSize-large", // 20px font-size and 32px min-height, or 32px height/width for icon-only content
}

export enum ButtonColor {
    Orange = "ButtonColor-orange",
    Blue = "ButtonColor-blue",
    Green = "ButtonColor-green",
    Red = "ButtonColor-red",
    Text = "ButtonColor-text",
    Clear = "ButtonColor-clear",
    Link = "ButtonColor-link",
    Icon = "ButtonColor-icon",
}

interface ButtonProps extends ComponentPropsWithRef<"button"> {
    /**
     * The event fired when the button is clicked.
     */
    onClick?: (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => void | Promise<void>
    /**
     * The text the button should display.
     */
    text?: ReactNode
    /**
     * Icon to display on the button, optional.
     */
    icon?: JSX.Element
    /**
     * The background color of the button, optional, by default is our brand orange color.
     */
    color?: ButtonColor
    /**
     * The size of the button. Defaults to small
     */
    size?: ButtonSize
    /**
     * Any extra classes you might need to provide. Space delimited.
     */
    className?: string
}

const defaultProps = {
    size: ButtonSize.Small,
    color: ButtonColor.Orange,
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            onClick,
            text,
            className,
            size = defaultProps.size,
            icon,
            color = defaultProps.color,
            ...props
        }: ButtonProps,
        ref,
    ) => {
        const hasIcon = Boolean(icon)
        const hasText = Boolean(text)
        const onlyHasIconClass = hasIcon && !hasText ? "onlyHasIcon" : undefined
        // "Button" vs "button" case matters here.
        const classList = ["Button", size, color, onlyHasIconClass]
        return (
            <button
                type="button"
                onClick={onClick}
                className={mergeClasses(classList, className)}
                ref={ref}
                {...props}
            >
                {text}
                {hasIcon && icon}
            </button>
        )
    },
)
